<template>
  <div class="main-right-bet">
    <div v-if="selectedGame.hasStatistics" class="main-right-bet__menu">
      <hr class="separator" />
      <div class="main-right-bet__menu__buttons">
        <div
          v-for="(section, index) of sections"
          :key="section.label"
          class="main-right-bet__menu__buttons__button"
          :class="{ 'main-right-bet__menu__buttons__button--active': isSelectedSection(index) }"
          @click="onButtonClick(section, index)"
        >
          <div class="main-right-bet__menu__buttons__button__text">
            {{ labelText(section) }}
          </div>
          <div class="main-right-bet__menu__buttons__button__icon">
            <img :src="`/icons/icn_${section.icon}`" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="main-right-bet__section-content">
      <KeepAlive>
        <Component
          :is="selectedComponent"
          :is-corners-goals-mobile="isCornersGoalsMobile"
          :await-for-bets="awaitForBets"
          :parlay-bets="parlayBets"
          :show-logos="showLogos"
        />
      </KeepAlive>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { RightBetCorners, RightBetResult, RightBetGoals } from '@/utils/analytics';

export default {
  name: 'MainRightBet',
  components: {
    Corners: () => import('@/components/Sections/GolTipster-v2/RightBet/Corners'),
    Goals: () => import('@/components/Sections/GolTipster-v2/RightBet/Goals'),
    Result: () => import('@/components/Sections/GolTipster-v2/RightBet/Results'),
  },
  props: {
    isCornersGoalsMobile: {
      type: Boolean,
      default: false,
    },
    awaitForBets: {
      type: Boolean,
      default: false,
    },
    parlayBets: {
      type: Object,
      default: () => ({}),
    },
    showLogos: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedSectionIndex: 0,
      selectedComponent: '',
      sections: [
        {
          label: 'Resultado',
          icon: 'result.svg',
          componentName: 'Result',
        },
        {
          label: 'Tiros de esquina',
          labelMobile: 'T. de esquina',
          icon: 'corner.svg',
          componentName: 'Corners',
        },
        {
          label: 'Goles',
          icon: 'goals.svg',
          componentName: 'Goals',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapState(['displayWidth']),
    ...mapState('betsGeneral', ['selectedGame']),
    labelText: function() {
      const vm = this;
      return section => {
        return section.labelMobile && vm.displayWidth <= 568 ? section.labelMobile : section.label;
      };
    },
  },
  methods: {
    ...mapMutations('betsGeneral', ['setRightBetComponent']),
    onButtonClick(section, index) {
      if (this.selectedSectionIndex !== index) {
        this.selectedSectionIndex = index;
        this.selectedComponent = section.componentName;
        this.setRightBetComponent(this.selectedComponent);
        this.sendEventAnalytics(this.selectedComponent);
      }
    },
    sendEventAnalytics(componentName) {
      let names = {
        Corners: () =>
          RightBetCorners({
            is_premium: this.getPremiumAccount,
            golstats: this.getIsGolstats,
          }),
        Result: () =>
          RightBetResult({
            is_premium: this.getPremiumAccount,
            golstats: this.getIsGolstats,
          }),
        Goals: () =>
          RightBetGoals({
            is_premium: this.getPremiumAccount,
            golstats: this.getIsGolstats,
          }),
      };
      names[componentName]();
    },
    isSelectedSection(index) {
      return this.selectedSectionIndex === index;
    },
  },
  mounted() {
    this.selectedComponent = this.sections[0].componentName;
  },
};
</script>

<style scoped lang="scss">
.main-right-bet {
  width: 100%;

  &__menu {
    display: flex;
    justify-content: center;
    position: relative;

    &__buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: fit-content;
      z-index: 1;
      background-color: white;
      padding: 0 0.375rem;

      &__button {
        min-width: 5.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: solid 1px #a4bbd0;
        font-family: Roboto-Regular, sans-serif;
        font-size: 1.25rem;
        padding: 0.5rem 1rem;
        margin: 0 0.375rem;
        cursor: pointer;

        &__text {
          line-height: 0.8;
          color: #3b485c;
        }

        &__icon {
          height: 2rem;
          width: 2rem;
          margin-left: 0.6rem;
        }

        &--active {
          border: solid 4px #cbee6b;
          background-color: rgba(203, 238, 107, 0.2);
          font-weight: 600;
        }

        @media screen and (max-width: 680px) {
          flex-direction: column-reverse;

          &__text {
          }

          &__icon {
            margin-left: 0;
            margin-bottom: 0.6rem;
          }
        }

        @media screen and (max-width: 568px) {
          flex-direction: column-reverse;

          &__text {
            font-size: 0.81rem;
          }
        }

        @media screen and (max-width: 346px) {
          min-width: min-content;
        }
      }
    }
  }
}

.separator {
  border-top: dashed 1px #94a8ba;
  margin: 0;
  position: absolute;
  width: 100%;
  top: 50%;
}
</style>
